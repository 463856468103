import React, { useEffect, useState } from "react";
import "./StaffMembers.scss";
import axios from "axios";
import Loader from "react-loader-spinner";
import StaffIndividual from "./StaffIndividual";

export default function StaffMembers(props) {
  const [staffMembers, setStaff] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    axios
      .get(`site/get_staff_members?id_club=${props.id_club}`)
      .then((res) => {
        setStaff(res.data);
        setLoaded(true);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <div className="staff-members-container">
        <div
          className="staff-members-title"
          style={{ color: props.config_options.section_title_color }}
        >
          Staff tehnic
        </div>
        {!loaded ? (
          <div className="loader-container">
            <Loader
              type="ThreeDots"
              color={props.config_options.main_color}
              height={80}
              width={80}
            />
          </div>
        ) : (
          <div className="staff-list">
            {staffMembers.map((item, index) => {
              if (Number(item.is_administrative) === 0) {
                return (
                  <StaffIndividual
                    item={item}
                    config_options={props.config_options}
                    key={index}
                  />
                );
              }
            })}
          </div>
        )}
      </div>
      <div className="staff-members-container">
        <div
          className="staff-members-title"
          style={{ color: props.config_options.section_title_color }}
        >
          Staff administrativ
        </div>
        {!loaded ? (
          <div className="loader-container">
            <Loader
              type="ThreeDots"
              color={props.config_options.main_color}
              height={80}
              width={80}
            />
          </div>
        ) : (
          <div className="staff-list">
            {staffMembers.map((item, index) => {
              if (Number(item.is_administrative) === 1) {
                return (
                  <StaffIndividual
                    item={item}
                    config_options={props.config_options}
                    key={index}
                  />
                );
              }
            })}
          </div>
        )}
      </div>
    </>
  );
}
