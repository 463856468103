import React, { useEffect, useState } from "react";
import "./Header.scss";
import { ReactComponent as FacebookIcon } from "../../../assets/icons/svg/facebook.svg";
import { ReactComponent as YoutubeIcon } from "../../../assets/icons/svg/youtube.svg";
import { ReactComponent as InstagramIcon } from "../../../assets/icons/svg/instagram.svg";
import { ReactComponent as EmailIcon } from "../../../assets/icons/svg/email.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/icons/svg/phone.svg";
import axios from "axios";

export default function ContactInfo(props) {
  const [contactDetails, setContactDetails] = useState({});

  useEffect(() => {
    axios
      .get(`site/get_contact_details?id_club=${props.id_club}`)
      .then((res) => setContactDetails(res.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div
      className="contact-info-container notranslate"
      style={{
        color: props.textColor,
        paddingRight: props.multiLang == 0 ? "50px" : "",
      }}
    >
      <div className="phone">
        <a
          href={`tel:${contactDetails.contact_phone}`}
          style={{
            display: "flex",
            alignItems: "center",
            color: props.textColor,
            textDecoration: "none",
          }}
        >
          <PhoneIcon
            fill={props.textColor}
            style={{ height: 20, paddingRight: 10 }}
          />
          <div className="phone-text">{contactDetails.contact_phone}</div>
        </a>
      </div>
      <div className="email">
        <a
          href={`mailto:${contactDetails.contact_email}`}
          style={{
            display: "flex",
            alignItems: "center",
            color: props.textColor,
            textDecoration: "none",
          }}
        >
          <EmailIcon
            fill={props.textColor}
            style={{ height: 20, paddingRight: 10 }}
          />
          <div className="email-text">{contactDetails.contact_email}</div>
        </a>
      </div>
      <div className="social-media">
        <a
          href={contactDetails.facebook_page_url}
          target="_blank"
          rel="noreferrer"
        >
          <FacebookIcon
            fill={props.textColor}
            style={{ height: 22, paddingRight: 10, paddingLeft: 10 }}
          />
        </a>
        <a
          href={contactDetails.youtube_page_url}
          rel="noreferrer"
          target="_blank"
        >
          <YoutubeIcon
            fill={props.textColor}
            style={{ height: 22, paddingRight: 10, paddingLeft: 10 }}
          />
        </a>
        <a
          href={contactDetails.instagram_page_url}
          rel="noreferrer"
          target="_blank"
        >
          <InstagramIcon
            fill={props.textColor}
            style={{ height: 22, paddingRight: 10, paddingLeft: 10 }}
          />
        </a>
      </div>
    </div>
  );
}
