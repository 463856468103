import React from "react";
import "./App.css";
import Homepage from "./components/pages/Homepage";
import Header from "./components/sections/Header/Header";
import Footer from "./components/sections/Footer/Footer";
import Sponsors from "./components/sections/Sponsors/Sponsors";
import Contact from "./components/sections/Contact/Contact";
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import News from "./components/pages/News/News";
import NewsIndividual from "./components/pages/NewsIndividual/NewsIndividual";
import Team from "./components/pages/Team/Team";
import Gallery from "./components/pages/Gallery/Gallery";
import Competitions from "./components/pages/Competitions/Competitions";
import CompetitionPage from "./components/pages/Competitions/CompetitionPage";
import About from "./components/pages/About/About";
import OnlinePayments from "./components/pages/OnlinePayments/OnlinePayments";
import OnlinePaymentsSuccess from "./components/pages/OnlinePayments/OnlinePaymentsSuccess";
import ScrollToTop from "./ScrollToTop"; //Scroll to top on page change
import Groups from "./components/pages/Groups/Groups";
import StaffMemberPage from "./components/pages/StaffMemberPage/StaffMemberPage";
import PlayerPage from "./components/pages/PlayerPage/PlayerPage";
import axios from "axios";
import Scroll from "./ScrollTop"; //Scroll to top button
import StaffMembers from "./components/pages/StaffMembers/StaffMembers";
import Loader from "./components/reusable/Loader/Loader";
import RegistrationForm from "./components/pages/PlayerFormRegistration/RegistrationForm";
import { doGTranslate } from "./assets/translate-script/translateScript";
import PrivacyPolicy from "./components/pages/PrivacyPolicy/PrivacyPolicy";
import { baseUrl } from "./utils/functions";
import Palmares from "./components/pages/Palmares/Palmares";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      main_details_received: false,
      id_club: "",
      club_name: "",
      homepage_description: "",
      theme: "",
      main_color: "",

      show_donations: "",
      website_settings: {},

      footer_text_color: "",
      config_options: {},
      lang: "ro",
      url_redirect_payment: "",
      multi_lang: "0",
      isLoading: true,
    };
    this.sponsorsRef = React.createRef();
    this.contactRef = React.createRef();

    // Request interceptor for capturing start time
    axios.interceptors.request.use(
      (request) => {
        request.time = { startTime: new Date() };
        return request;
      },
      (err) => {
        return Promise.reject(err);
      }
    );

    // Response interceptor for computing duration
    axios.interceptors.response.use(
      (response) => {
        response.config.time.endTime = new Date();
        response.duration =
          response.config.time.endTime - response.config.time.startTime;
        return response;
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  scrollToSponsors = () =>
    this.sponsorsRef.scrollIntoView({ behavior: "smooth", block: "start" });

  scrollToContact = () =>
    this.contactRef.scrollIntoView({ behavior: "smooth", block: "start" });

  componentDidMount = () => {
    // DEV
    // const url = "cityfciasi.tfb.ai";
    // LIVE
    // const url = window.location.host;
    const urls = {
      1: "academiapoliiasi.ro",
      2: "academiadidi.ro",
      4: "cityfciasi.ro",
      5: "academiarapid.tfb.ai",
      6: "demo.tfb.ai",
      25: "steauamagica.ro",
      39: "csfcpietricica.ro",
      43: "geto-dacii.com",
      44: "tigersvaslui.tfb.ai",
      45: "startsuceava.tfb.ai",
      46: "viitorularad.tfb.ai",
      50: "premieriasi.tfb.ai",
      69: "arocampulung.tfb.ai",
      71: "scoaladefotbalrapid.tfb.ai",
      72: "kidstampa.tfb.ai",
      76: "cscoltea.ro",
      234: "aogtest.tfb.ai",
    };
    const selected_club = 234;
    const url = window.location.host.includes("localhost")
      ? urls[selected_club]
      : window.location.host;
    const url_redirect_payment = window.location.host;

    this.setState({
      url_redirect_payment: url_redirect_payment,
    });

    axios.get(`site/get_main_site_details?url=${url}`).then((res) => {
      this.setState({
        id_club: res.data.id_club,
        club_name: res.data.club_name,
        homepage_description: res.data.description,
        main_details_received: true,
        theme: res.data.theme,
        main_color: res.data.main_color,
        config_options: res.data.config_options,
        multi_lang: res.data.multi_lang,
        show_donations: res.data.show_donations,
        website_settings: res.data.website_settings,
      });
      const favicon = document.getElementById("favicon");
      favicon.href = baseUrl(`uploads/club_logos/${res.data.id_club}.png`);
      if (res.duration < 2000) {
        setTimeout(() => {
          this.setState({ isLoading: false });
        }, 2000 - res.duration);
      } else {
        this.setState({ isLoading: false });
      }
    });

    var c = document.cookie.split("; "),
      cookies = {},
      i,
      C;

    for (i = c.length - 1; i >= 0; i--) {
      C = c[i].split("=");
      cookies[C[0]] = C[1];
    }
    let lang;
    if (cookies["googtrans"] == undefined) {
      lang = "ro";
    } else {
      lang = cookies["googtrans"].split("/")[2];
    }
    this.setState({
      lang: lang,
    });
  };

  setLang = (event) => {
    this.setState({ lang: event.value });
  };

  render() {
    return (
      <>
        {this.state.isLoading ? (
          <Loader />
        ) : (
          this.state.multi_lang == 1 && (
            <>
              <select
                onChange={(event) => doGTranslate(event.target.value)}
                className="notranslate lang-select"
                id="lang-select"
              >
                <option
                  value="ro|ro"
                  selected={this.state.lang == "ro" ? true : false}
                >
                  RO
                </option>
                <option
                  value="ro|de"
                  selected={this.state.lang == "de" ? true : false}
                >
                  DE
                </option>
              </select>
              <img
                className="lang-flag"
                id="ro-lang"
                src={baseUrl("uploads/site/lang_flags/ro.png")}
                style={{ display: this.state.lang == "ro" ? "block" : "none" }}
                alt="Romania Flag"
              ></img>
              <img
                className="lang-flag"
                id="de-lang"
                src={baseUrl("uploads/site/lang_flags/de.png")}
                style={{ display: this.state.lang == "de" ? "block" : "none" }}
                alt="flag"
              ></img>
            </>
          )
        )}
        <div id="google_translate_element2">
          <Router>
            <ScrollToTop>
              {this.state.main_details_received && (
                <div
                  className="app"
                  style={{
                    backgroundColor: this.state.config_options.background_color,
                  }}
                >
                  {this.state.website_settings
                    ?.show_only_online_payments_iframe != 1 && (
                    <Header
                      scrollToSponsors={this.scrollToSponsors}
                      scrollToContact={this.scrollToContact}
                      id_club={this.state.id_club}
                      club_name={this.state.club_name}
                      color={this.state.config_options.header_color}
                      textColor={this.state.config_options.header_option_color}
                      theme={this.state.theme}
                      config_options={this.state.config_options}
                      onSetLang={this.setLang}
                      showOnlinePayments={
                        this.state.website_settings?.show_online_payments
                      }
                      showDonations={this.state.show_donations}
                      showOnlyOnlinePayments={
                        this.state.website_settings?.show_only_online_payments
                      }
                      showEnrolmentForm={
                        this.state.website_settings?.show_enrolment_request_form
                      }
                      multiLang={this.state.multi_lang}
                    />
                  )}
                  <Switch>
                    <Route
                      exact
                      path="/about"
                      forceRefresh={true}
                      render={(props) =>
                        this.state.website_settings
                          ?.show_only_online_payments == 1 ? (
                          <Redirect to="/online-payments" />
                        ) : (
                          <About
                            {...props}
                            id_club={this.state.id_club}
                            config_options={this.state.config_options}
                          />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/privacy-policy"
                      forceRefresh={true}
                      render={(props) => (
                        <PrivacyPolicy
                          {...props}
                          id_club={this.state.id_club}
                          config_options={this.state.config_options}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/online-payments"
                      render={(props) =>
                        this.state.website_settings?.show_online_payments ==
                        0 ? (
                          <Redirect to="/" />
                        ) : (
                          <OnlinePayments
                            {...props}
                            id_club={this.state.id_club}
                            club_name={this.state.club_name}
                            config_options={this.state.config_options}
                            lang={this.state.lang}
                            urlRedirectPayment={this.state.url_redirect_payment}
                            onlyIframe={
                              this.state.website_settings
                                ?.show_only_online_payments_iframe
                            }
                            feePassedToCustomer={
                              this.state.website_settings
                                ?.fee_passed_to_customer
                            }
                            editableMonthlyTax={
                              this.state.website_settings?.editable_monthly_tax
                            }
                          />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/online-payments-success"
                      render={(props) =>
                        this.state.website_settings?.show_online_payments ==
                        0 ? (
                          <Redirect to="/" />
                        ) : (
                          <OnlinePaymentsSuccess
                            {...props}
                            id_club={this.state.id_club}
                            config_options={this.state.config_options}
                            feePassedToCustomer={
                              this.state.website_settings
                                ?.fee_passed_to_customer
                            }
                          />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/enrolment-request-form"
                      render={(props) =>
                        this.state.website_settings
                          ?.show_enrolment_request_form == 0 ? (
                          <Redirect to="/" />
                        ) : (
                          <RegistrationForm
                            {...props}
                            id_club={this.state.id_club}
                            club_name={this.state.club_name}
                            config_options={this.state.config_options}
                            lang={this.state.lang}
                            urlRedirectPayment={this.state.url_redirect_payment}
                            onlyIframe={
                              this.state.website_settings
                                ?.show_only_online_payments_iframe
                            }
                            feePassedToCustomer={
                              this.state.website_settings
                                ?.fee_passed_to_customer
                            }
                            editableMonthlyTax={
                              this.state.website_settings?.editable_monthly_tax
                            }
                          />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/news"
                      render={(props) =>
                        this.state.website_settings
                          ?.show_only_online_payments == 1 ? (
                          <Redirect to="/online-payments" />
                        ) : (
                          <News
                            {...props}
                            id_club={this.state.id_club}
                            config_options={this.state.config_options}
                          />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/news/:title-:id"
                      render={(props) =>
                        this.state.website_settings
                          ?.show_only_online_payments == 1 ? (
                          <Redirect to="/online-payments" />
                        ) : (
                          <NewsIndividual
                            {...props}
                            id_club={this.state.id_club}
                            config_options={this.state.config_options}
                          />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/palmares"
                      render={(props) =>
                        this.state.website_settings
                          ?.show_only_online_payments == 1 ? (
                          <Redirect to="/online-payments" />
                        ) : (
                          <Palmares
                            {...props}
                            id_club={this.state.id_club}
                            config_options={this.state.config_options}
                          />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/groups"
                      render={(props) =>
                        this.state.website_settings
                          ?.show_only_online_payments == 1 ? (
                          <Redirect to="/online-payments" />
                        ) : (
                          <Groups
                            {...props}
                            id_club={this.state.id_club}
                            config_options={this.state.config_options}
                          />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/groups/:team_name-:id"
                      render={(props) =>
                        this.state.website_settings
                          ?.show_only_online_payments == 1 ? (
                          <Redirect to="/online-payments" />
                        ) : (
                          <Team
                            {...props}
                            id_club={this.state.id_club}
                            config_options={this.state.config_options}
                          />
                        )
                      }
                    />
                    <Route
                      exact
                      path={`/player/:name-:id`}
                      render={(props) =>
                        this.state.website_settings
                          ?.show_only_online_payments == 1 ? (
                          <Redirect to="/online-payments" />
                        ) : (
                          <PlayerPage
                            {...props}
                            id_club={this.state.id_club}
                            config_options={this.state.config_options}
                          />
                        )
                      }
                    />

                    <Route
                      exact
                      path="/photos"
                      render={(props) =>
                        this.state.website_settings
                          ?.show_only_online_payments == 1 ? (
                          <Redirect to="/online-payments" />
                        ) : (
                          <Gallery
                            {...props}
                            id_club={this.state.id_club}
                            config_options={this.state.config_options}
                          />
                        )
                      }
                    />

                    <Route
                      exact
                      path="/competitions"
                      render={(props) =>
                        this.state.website_settings
                          ?.show_only_online_payments == 1 ? (
                          <Redirect to="/online-payments" />
                        ) : (
                          <Competitions
                            {...props}
                            id_club={this.state.id_club}
                            config_options={this.state.config_options}
                          />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/competitions/:competition_name-:id"
                      render={(props) =>
                        this.state.website_settings
                          ?.show_only_online_payments == 1 ? (
                          <Redirect to="/online-payments" />
                        ) : (
                          <CompetitionPage
                            {...props}
                            id_club={this.state.id_club}
                            config_options={this.state.config_options}
                          />
                        )
                      }
                    />

                    <Route
                      exact
                      path="/staff"
                      render={(props) =>
                        this.state.website_settings
                          ?.show_only_online_payments == 1 ? (
                          <Redirect to="/online-payments" />
                        ) : (
                          <StaffMembers
                            {...props}
                            id_club={this.state.id_club}
                            config_options={this.state.config_options}
                          />
                        )
                      }
                    />

                    <Route
                      exact
                      path="/staff/:staff_member_name-:id"
                      render={(props) =>
                        this.state.website_settings
                          ?.show_only_online_payments == 1 ? (
                          <Redirect to="/online-payments" />
                        ) : (
                          <StaffMemberPage
                            {...props}
                            id_club={this.state.id_club}
                            config_options={this.state.config_options}
                          />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/"
                      render={(props) =>
                        this.state.website_settings
                          ?.show_only_online_payments == 1 ? (
                          <Redirect to="/online-payments" />
                        ) : (
                          <Homepage
                            {...props}
                            id_club={this.state.id_club}
                            club_name={this.state.club_name}
                            description={this.state.homepage_description}
                            theme={this.state.theme}
                            main_color={this.state.main_color}
                            config_options={this.state.config_options}
                            website_settings={this.state.website_settings}
                          />
                        )
                      }
                    />
                  </Switch>
                  {this.state.website_settings?.show_only_online_payments ==
                    0 && (
                    <div ref={(ref) => (this.sponsorsRef = ref)}>
                      <Sponsors
                        id_club={this.state.id_club}
                        config_options={this.state.config_options}
                      />
                    </div>
                  )}
                  {this.state.website_settings
                    ?.show_only_online_payments_iframe != 1 &&
                    this.state.website_settings?.show_only_online_payments !=
                      1 && (
                      <div ref={(ref) => (this.contactRef = ref)}>
                        <Contact
                          id_club={this.state.id_club}
                          club_name={this.state.club_name}
                          config_options={this.state.config_options}
                        />
                      </div>
                    )}
                  {this.state.website_settings
                    ?.show_only_online_payments_iframe != 1 && (
                    <Footer
                      scrollToSponsors={this.scrollToSponsors}
                      scrollToContact={this.scrollToContact}
                      id_club={this.state.id_club}
                      club_name={this.state.club_name}
                      backgroundColor={this.state.main_color}
                      theme={this.state.theme}
                      config_options={this.state.config_options}
                      showOnlinePayments={
                        this.state.website_settings?.show_online_payments
                      }
                      showDonations={this.state.show_donations}
                      showOnlyOnlinePayments={
                        this.state.website_settings?.show_only_online_payments
                      }
                      showEnrolmentForm={
                        this.state.website_settings?.show_enrolment_request_form
                      }
                    />
                  )}
                  <Scroll
                    showBelow={250}
                    config_options={this.state.config_options}
                  />
                </div>
              )}
            </ScrollToTop>
          </Router>
        </div>
      </>
    );
  }
}

export default App;
