import React, { useState } from "react";
import ContactInfo from "./ContactInfo";
import "./Header.scss";
import mobile_menu from "../../../assets/icons/menu-mobile.png";
import { ReactComponent as CloseIcon } from "../../../assets/icons/svg/close.svg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/svg/arrow-down.svg";
import { NavLink, useLocation } from "react-router-dom";
import { css } from "@emotion/css";
import { baseUrl } from "../../../utils/functions";

export default function Header(props) {
  const menuOptions = [
    {
      text: "Despre noi",
      subtitles: false,
      key: "about_us",
      path: "/about",
      scrollable: false,
    },
    {
      text: "Competiții",
      subtitles: false,
      key: "competitions",
      path: "/competitions",
      scrollable: false,
    },
    {
      text: `${Number(props.id_club) === 43 ? "Echipă" : "Grupe"} `,
      subtitles: false,
      key: "groups",
      path: "/groups",
      scrollable: false,
    },
    {
      text: "Staff",
      subtitles: false,
      key: "staff",
      path: "/staff",
      scrollable: false,
    },
    {
      text: "Palmares",
      subtitles: false,
      key: "palmares",
      path: "/palmares",
      scrollable: false,
    },

    // {
    //     text: "Parteneri",
    //     subtitles: false,
    //     key: "partners",
    //     path: "/",
    //     scrollable: true,
    // },
    {
      text: `${Number(props.id_club) === 43 ? "Geto-Dacii Media" : "Știri"} `,
      subtitles: false,
      key: "news",
      path: "/news",
      scrollable: false,
    },
    {
      text: "Contact",
      subtitles: false,
      key: "contact",
      path: "/",
      scrollable: true,
    },
    {
      text: "Plată online",
      subtitles: false,
      key: "online-payments",
      path: "/online-payments",
      scrollable: false,
    },
    {
      text: "Formular înscriere",
      subtitles: false,
      key: "enrolment-request-form",
      path: "/enrolment-request-form",
      scrollable: false,
    },
  ];

  const location = useLocation();
  const [mobileMenuActive, setMobileMenuActive] = useState(false);

  return (
    <div className="header-container">
      <div
        className="page-lang"
        style={{
          display: "flex",
          alignItems: "center",
          height: "50px",
          justifyContent: "flex-end",
        }}
      >
        <ContactInfo
          id_club={props.id_club}
          textColor={props.textColor}
          multiLang={props.multiLang}
        />
      </div>
      <div
        className="menu-header"
        style={{
          backgroundColor: props.color,
          borderRadius: props.theme == 2 ? 10 : 0,
        }}
      >
        <NavLink
          to={props.showOnlyOnlinePayments == 1 ? "/online-payments" : "/"}
          style={{ textDecoration: "none", color: "black" }}
          className="badge-name"
        >
          <img
            src={baseUrl(`uploads/club_logos/${props.id_club}.png`)}
            alt="logo"
            className="mobile-logo"
          />
          <div
            className="title-text notranslate"
            style={{ color: props.textColor }}
          >
            {props.club_name}
          </div>
        </NavLink>
        {props.showOnlyOnlinePayments == 0 && (
          <div className="menu-options">
            {menuOptions
              .filter((elem) =>
                props.showOnlinePayments == 1
                  ? true
                  : elem.key != "online-payments"
              )
              .filter((elem) =>
                props.showEnrolmentForm == 1
                  ? true
                  : elem.key != "enrolment-request-form"
              )
              .filter((elem) =>
                Number(props.id_club) !== 43 ? elem.key !== "palmares" : true
              )
              .map((item, index) => {
                return (
                  <div key={"nav-item-" + index}>
                    {item.scrollable ? (
                      <div
                        className={css`
                          text-align: center;
                          align-self: center;
                          padding-left: 50px;
                          padding-right: 50px;
                          font-size: 16px;
                          height: 100%;
                          display: flex;
                          align-items: center;
                          color: ${props.textColor};
                          cursor: pointer;
                          &:hover {
                            color: ${props.color};
                            background-color: ${props.textColor};
                            border-radius: ${props.theme == 2 ? "40px" : "0px"};
                          }
                        `}
                        onClick={
                          item.key === "partners"
                            ? () => props.scrollToSponsors()
                            : () => props.scrollToContact()
                        }
                        style={{
                          borderLeft:
                            props.theme == 2
                              ? "none"
                              : `solid 1px ${props.config_options.header_lines_color}`,
                        }}
                      >
                        <div className="menu-option-text">
                          {item.text}
                          {item.subtitles && (
                            <ArrowDown className="arrow-down-menu" />
                          )}
                        </div>
                      </div>
                    ) : (
                      <NavLink
                        to={item.path}
                        style={{
                          textDecoration: "none",
                          color: props.textColor,
                        }}
                      >
                        <div
                          className={css`
                            text-align: center;
                            align-self: center;
                            padding-left: 50px;
                            padding-right: 50px;
                            font-size: 16px;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            color: ${item.path === location.pathname &&
                            item.path !== "/"
                              ? props.color
                              : props.textColor};
                            background-color: ${item.path ===
                              location.pathname && item.path !== "/"
                              ? props.textColor
                              : props.color};
                            border-radius: ${item.path === location.pathname &&
                            item.path !== "/" &&
                            props.theme == 2
                              ? "40px"
                              : "0px"};
                            cursor: pointer;
                            &:hover {
                              color: ${props.color};
                              background-color: ${props.textColor};
                              border-radius: ${props.theme == 2
                                ? "40px"
                                : "0px"};
                            }
                          `}
                          style={{
                            borderLeft:
                              props.theme == 2
                                ? "none"
                                : `solid 1px ${props.config_options.header_lines_color}`,
                          }}
                        >
                          <div className="menu-option-text">
                            {item.text}
                            {item.subtitles && (
                              <ArrowDown className="arrow-down-menu" />
                            )}
                          </div>
                        </div>
                      </NavLink>
                    )}
                  </div>
                );
              })}
          </div>
        )}
        {props.showOnlyOnlinePayments == 0 && (
          <div className="menu-mobile">
            {mobileMenuActive === false ? (
              <img
                src={mobile_menu}
                className="menu-mobile-img"
                alt=""
                onClick={() => setMobileMenuActive(true)}
              />
            ) : (
              <CloseIcon
                fill={props.textColor}
                className="menu-mobile-img"
                onClick={() => setMobileMenuActive(false)}
              />
            )}
          </div>
        )}
      </div>
      {mobileMenuActive && props.showOnlyOnlinePayments == 0 && (
        <div
          className="menu-mobile-content"
          style={{ backgroundColor: props.color }}
        >
          {menuOptions
            .filter((elem) =>
              props.showOnlinePayments == 1
                ? true
                : elem.key != "online-payments"
            )
            .filter((elem) =>
              props.showEnrolmentForm == 1
                ? true
                : elem.key != "enrolment-request-form"
            )
            .filter((elem) =>
              (Number(props.id_club) === 234 || Number(props.id_club) === 43) &&
              elem.key === "palmares"
                ? false
                : true
            )
            .map((item, index) => {
              return (
                <div key={"nav-item-mobile" + index}>
                  {!item.scrollable ? (
                    <NavLink
                      to={item.path}
                      style={{
                        textDecoration: "none",
                        color: props.textColor,
                      }}
                      onClick={() => setMobileMenuActive(false)}
                    >
                      <div
                        className="mobile-menu-option"
                        style={{
                          borderBottom: `solid 1px ${props.config_options.header_lines_color}`,
                        }}
                      >
                        {item.text}
                      </div>
                    </NavLink>
                  ) : (
                    <div
                      className="mobile-menu-option"
                      style={{
                        color: props.textColor,
                        borderBottom: `solid 1px ${props.config_options.header_lines_color}`,
                      }}
                      onClick={
                        item.key === "partners"
                          ? () => props.scrollToSponsors()
                          : () => props.scrollToContact()
                      }
                    >
                      {item.text}
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}
