import React, { useEffect, useState } from "react";
import "./Footer.scss";
import { ReactComponent as HomeIcon } from "../../../assets/icons/svg/home.svg";
import { ReactComponent as EmailIcon } from "../../../assets/icons/svg/email.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/icons/svg/phone.svg";
import arrow_down from "../../../assets/icons/arrow-down.png";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../../utils/functions";

export default function Footer(props) {
  const history = useHistory();
  const menuOptions = [
    { key: "about", text: "Despre noi", subtitles: false, route: "/about" },
    {
      key: "competitions",
      text: "Competiții",
      subtitles: false,
      route: "/competitions",
    },
    { key: "groups", text: "Grupe", subtitles: false, route: "/groups" },
    { key: "staff", text: "Staff", subtitles: false, route: "/staff" },
    // { key: "partners", text: "Parteneri", subtitles: false },
    { key: "news", text: "Știri", subtitles: false, route: "/news" },
    { key: "contact", text: "Contact", subtitles: false },
    {
      key: "online-payments",
      text: "Plată online",
      subtitles: false,
      route: "/online-payments",
    },
    {
      key: "enrolment-request-form",
      text: "Formular înscriere",
      subtitles: false,
      route: "/enrolment-request-form",
    },
  ];

  const [contactDetails, setContactDetails] = useState({});

  useEffect(() => {
    axios
      .get(`site/get_contact_details?id_club=${props.id_club}`)
      .then((res) => setContactDetails(res.data))
      .catch((err) => console.log(err));
  }, [props.id_club]);

  return (
    <>
      <div
        className="footer"
        style={{
          backgroundColor: props.backgroundColor,
          borderRadius: props.theme == 2 ? 10 : 0,
          color: props.config_options.footer_text_color,
        }}
      >
        <div
          className="menu-row"
          style={{
            borderBottom: `solid 1px ${props.config_options.footer_line_color}`,
          }}
        >
          <div className="team-badge">
            <img
              src={baseUrl(`uploads/club_logos/${props.id_club}.png`)}
              alt="logo"
            />
          </div>
          <div className="team-name">{props.club_name}</div>
          {props.showOnlyOnlinePayments == 0 && (
            <div className="menu-options">
              {menuOptions
                .filter((elem) =>
                  props.showOnlinePayments == 1
                    ? true
                    : elem.key != "online-payments"
                )
                .filter((elem) =>
                  props.showEnrolmentForm == 1
                    ? true
                    : elem.key != "enrolment-request-form"
                )
                .map((item, index) => {
                  return (
                    <div
                      className="menu-option"
                      key={index}
                      onClick={
                        item.route
                          ? () => history.push(item.route)
                          : item.key === "contact"
                          ? () => props.scrollToContact()
                          : () => props.scrollToSponsors()
                      }
                    >
                      <div className="menu-option-text">
                        {item.text}
                        {item.subtitles && <img src={arrow_down} alt="opt" />}
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
        <div
          className="address-row notranslate"
          style={{
            borderBottom: `solid 1px ${props.config_options.footer_line_color}`,
          }}
        >
          <div className="address">
            <HomeIcon
              fill={props.config_options.footer_text_color}
              className="footer-details-icon"
              alt="home"
            />
            {contactDetails?.contact_address}
          </div>
          <div className="phone">
            <a
              href={`tel:${contactDetails?.contact_phone}`}
              style={{
                display: "flex",
                alignItems: "center",
                color: props.config_options.footer_text_color,
                textDecoration: "none",
              }}
            >
              <PhoneIcon
                fill={props.config_options.footer_text_color}
                className="footer-details-icon"
                alt="phone"
              />
              {contactDetails?.contact_phone}
            </a>
          </div>
          <div className="mail">
            <a
              href={`mailto:${contactDetails?.contact_email}`}
              style={{
                display: "flex",
                alignItems: "center",
                color: props.config_options.footer_text_color,
                textDecoration: "none",
              }}
            >
              <EmailIcon
                fill={props.config_options.footer_text_color}
                className="footer-details-icon"
                alt="email"
              />
              {contactDetails?.contact_email}
            </a>
          </div>
        </div>
        <div className="copyright-row notranslate">
          <div>
            <a
              href="https://anpc.ro/"
              target="_blank"
              rel="noreferrer"
              style={{
                color: props.config_options.footer_text_color,
                textDecoration: "inherit",
              }}
            >
              {"ANPC"}
            </a>
          </div>
          <div className="sp-gol" />
          <div>{"Copyright Brandweb " + new Date().getFullYear()}</div>
        </div>
      </div>
      <div className="extra-footer notranslate">
        <a
          href="https://www.thefootballbrain.ro/"
          style={{
            color: props.config_options.footer_brandweb_color,
            textDecoration: "inherit",
          }}
        >
          <div>{"Powered by The Football Brain"}</div>
        </a>
      </div>
    </>
  );
}
