import React from "react";
import "./Social.scss";
import { ReactComponent as LeftArrow } from "../../../assets/icons/svg/arrow-left.svg";
import { ReactComponent as RightArrow } from "../../../assets/icons/svg/arrow-right.svg";
import { ReactComponent as FacebookLogo } from "../../../assets/icons/svg/facebook.svg";
// import { ReactComponent as InstagramLogo } from "../../../assets/icons/svg/instagram.svg";
import { ReactComponent as YoutubeLogo } from "../../../assets/icons/svg/youtube.svg";
import SocialElement from "./SocialElement";
import AliceCarousel from "react-alice-carousel";
import axios from "axios";
import { css } from "@emotion/css";

const socialOptions = [
  { value: "all", text: "Toate" },
  { value: "facebook", text: "Facebook" },
  { value: "youtube", text: "YouTube" },
];

class Social extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: "all",
      items: [],
    };
  }

  componentDidMount = () => {
    this.getPosts();
  };

  getPosts = () => {
    axios
      .get(
        `site/get_social_posts?id_club=${this.props.id_club}&filter=${this.state.selectedOption}`
      )
      .then((res) => this.setState({ items: res.data }))
      .catch((err) => console.log(err));
  };

  render() {
    const responsive = {
      0: { items: 1 },
      740: { items: 2 },
      1440: { items: 3 },
      1850: { items: 4 },
    };

    return (
      <>
        {this.state.items.length > 0 && (
          <div className="social-section">
            <div
              className="social-section-title"
              style={{ color: this.props.config_options.section_title_color }}
            >
              Social media
            </div>
            <div className="social-media-menu">
              {socialOptions.map((item, index) => (
                <div
                  key={"social-option-" + index}
                  className={css`
                    padding-left: 50px;
                    padding-right: 50px;
                    background-color: ${this.state.selectedOption === item.value
                      ? this.props.config_options.header_option_color
                      : ""};
                    color: ${this.state.selectedOption === item.value
                      ? this.props.config_options.header_color
                      : this.props.config_options.header_option_color};
                    border-radius: ${this.state.selectedOption === item.value &&
                    this.props.config_options.id_theme == 2
                      ? "12px 12px 0px 0px"
                      : "0px"};
                    height: 100%;
                    display: flex;
                    align-items: center;
                    border-bottom: 2px solid
                      ${this.props.config_options.header_option_color};
                    fill: ${this.props.config_options.header_option_color};
                    cursor: pointer;
                    &:hover {
                      color: ${this.props.config_options.header_color};
                      fill: ${this.props.config_options.header_color};
                      background-color: ${this.props.config_options
                        .header_option_color};
                      border-radius: ${this.props.theme == 2
                        ? "12px 12px 0px 0px"
                        : "0px"};
                    }
                  `}
                  onClick={() =>
                    this.setState({ selectedOption: item.value }, this.getPosts)
                  }
                >
                  {item.value === "facebook" && (
                    <FacebookLogo
                      className="social-media-menu-icon"
                      fill={
                        this.state.selectedOption === "facebook"
                          ? this.props.config_options.header_color
                          : ""
                      }
                    />
                  )}
                  {item.value === "youtube" && (
                    <YoutubeLogo
                      className="social-media-menu-icon"
                      fill={
                        this.state.selectedOption === "youtube"
                          ? this.props.config_options.header_color
                          : ""
                      }
                    />
                  )}
                  {item.text}
                </div>
              ))}
            </div>
            <div className="social-media-mobile-menu">
              <select
                className="menu-mobile-dropdown"
                id="social-options"
                style={{
                  backgroundColor: this.props.config_options.side_buttons_color,
                  color: this.props.config_options.expand_photo_button_color,
                  borderRadius:
                    this.props.config_options.id_theme == 2 ? 15 : 0,
                }}
                onChange={(e) => {
                  this.setState(
                    { selectedOption: e.target.value },
                    this.getPosts
                  );
                }}
              >
                <option value="all">Toate canalele</option>
                <option value="facebook">Facebook</option>
                <option value="youtube">Youtube</option>
                {/* <option value="instagram">Instagram</option> */}
              </select>
            </div>
            <div className="social-carousel-container">
              <AliceCarousel
                mouseTracking
                disableDotsControls
                disableButtonsControls
                responsive={responsive}
                ref={(el) => (this.Carousel = el)}
              >
                {this.state.items.map((item, index) => {
                  return (
                    <SocialElement
                      key={"social-elem-" + index}
                      item={item}
                      theme={this.props.theme}
                      config_options={this.props.config_options}
                    />
                  );
                })}
              </AliceCarousel>
              {this.state.items.length > 0 && (
                <>
                  <div className="social-carousel-buttons-mobile">
                    <div
                      className="prev-button-mobile-news"
                      onClick={() => this.Carousel.slidePrev()}
                      style={{
                        backgroundColor:
                          this.props.config_options.side_buttons_color,
                        borderRadius:
                          this.props.config_options.id_theme == 2 ? "50%" : 0,
                      }}
                    >
                      <LeftArrow
                        fill={this.props.config_options.play_icon_color}
                        className="prev-button-img"
                      />
                    </div>
                    <div
                      onClick={() => this.Carousel.slideNext()}
                      className="next-button-mobile-news"
                      style={{
                        backgroundColor:
                          this.props.config_options.side_buttons_color,
                        borderRadius:
                          this.props.config_options.id_theme == 2 ? "50%" : 0,
                      }}
                    >
                      <RightArrow
                        fill={this.props.config_options.play_icon_color}
                        className="next-button-img"
                      />
                    </div>
                    {/* <div className="social-media-see-all-mobile">
              <Button>
                Vezi toate
                <img
                  src={arrow_black}
                  alt=""
                  className="social-media-see-all-icon"
                />
              </Button>
            </div> */}
                  </div>

                  <div className="social-carousel-buttons-desktop">
                    <div
                      className="prev-button-desktop"
                      onClick={() => this.Carousel.slidePrev()}
                      style={{
                        borderRadius:
                          this.props.config_options.id_theme == 2
                            ? "0px 40px 40px 0px"
                            : "",
                        backgroundColor:
                          this.props.config_options.side_buttons_color,
                      }}
                    >
                      <LeftArrow
                        className="prev-button-img"
                        fill={this.props.config_options.play_icon_color}
                      />
                    </div>
                    <div
                      onClick={() => this.Carousel.slideNext()}
                      className="next-button-desktop"
                      style={{
                        borderRadius:
                          this.props.config_options.id_theme == 2
                            ? "40px 0px 0px 40px"
                            : "",
                        backgroundColor:
                          this.props.config_options.side_buttons_color,
                      }}
                    >
                      <RightArrow
                        className="next-button-img"
                        fill={this.props.config_options.play_icon_color}
                      />
                    </div>
                  </div>
                </>
              )}
              {/* <div className="social-media-see-all-desktop">
            <Button>
              Vezi toate
              <img
                src={arrow_black}
                alt=""
                className="social-media-see-all-icon"
              />
            </Button>
          </div> */}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Social;
